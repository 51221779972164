<template>
  <div class="eshop-page">
    <Nav />
    <div class="eshop-top">
      <h1 class="title">欢迎来到 HARTING eShop</h1>
      <svg
        class="arrow"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
          fill="white"
          fill-opacity="0.7"
        />
      </svg>
      <p class="text">
        HARTING浩亭研发，制造和销售电气和电子连接器，设备接口，网络元件和电缆组件，适用于厂房里的网络系统，机械或能源及信号传输应用。我们的产品能应用于机械及厂房工厂，工厂自动化，发电及输配电，以及工业电子和电信行业。
      </p>
    </div>
    <div class="card-wrap">
      <div class="card-list">
        <div v-for="(item, index) in cardList" :key="index" class="card">
          <a :href="item.link">
            <div class="img-box">
              <img :src="item.imgUrl" alt="" :class="item.size" />
            </div>
            <div class="text">
              {{ item.name }}
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="btn-wrap">
      <div class="btn-link">
        <a href="https://baidu.com">去官网看看</a>
      </div>
    </div>
    <Footer class="footer" />
    <div style="width:100%; height: 54px"></div>
  </div>
</template>
<script>
import Footer from "../../component/footer.vue";
import Nav from "../../component/nav.vue";
export default {
  name: "ESHOP",
  components: {
    Footer,
    Nav
  },
  data() {
    return {
      cardList: [
        {
          size: "full",
          link: "https://baidu.com",
          name: "电子商店新消息",
          imgUrl: require("../../image/eshop-01.png"),
        },
        {
          size: "full",
          link: "/",
          name: "工业用连接器Han®",
          imgUrl: require("../../image/eshop-02.png"),
        },
        {
          size: "full",
          link: "",
          name: "工业以太网交換机",
          imgUrl: require("../../image/eshop-03.png"),
        },
        {
          size: "full",
          link: "",
          name: "PCB连接器",
          imgUrl: require("../../image/eshop-04.png"),
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.eshop-page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  .eshop-top {
    padding: 32px;
    .title {
      color: #212027;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 48px */
    }
    .arrow {
      margin-bottom: 8px;
    }
    .text {
      color: #535359;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
    }
  }
  .card-list {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    padding: 0 18px 18px 18px;
    justify-content: space-between;
    flex-wrap: wrap;
    .card {
      margin-bottom: 16px;
      width: 160px;
      min-height: 52px;
      border-radius: 24px;
      border: 1px solid #fff;
      background: #fff;
      overflow: hidden;
      .img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 160px;
        background: @card-bg;
        img {
          object-fit: contain;
        }
      }
      .full {
        width: 100%;
        height: 100%;
      }
      .small {
        width: 110px;
        height: 116px;
      }
      .text {
        padding: 16px 10px;
        color: #212027;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 19.2px */
      }
    }
  }
  .btn-wrap {
    margin-bottom: 32px;
    padding: 0 32px;
    .btn-link {
      box-sizing: border-box;
      width: 160px;
      height: 40px;
      padding: 12px 24px;
      border-radius: 40px;
      background: @theme-color;
      color: #212027;
      font-size: 16px;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 19.2px */
    }
  }
}
</style>
